<template>
  <div class="container with-sidebar">
    <Sidebar />
    <div>
      <div>
        <h1>Animales</h1>
        <p>
          Este es un esfuerzo de restauración del ecosistema y también una operación para producir alimentos para los
          humanos. Durante siglos humanos y algunas especies animales han entrado en una relación simbiótica y de ahí
          viene que algunos las especies se consideran animales de granja, mientras que otras especies son animales
          salvajes.
        </p>

        <h2>Animales de granja</h2>
        <p>
          Nuestros animales de granja actualmente son bovinos (Bos taurus), cerdos (Sus scrofa domesticus), ovejas (Ovis
          aries), perros (Canis familiaris) y caballos (Equus ferus caballus). Puedes aprender más sobre cada especie y
          por qué hay aquí siguiendo los enlaces a la izquierda.
        </p>
        <p v-if="hasListAnimals" class="py-2">
          Algunas especies animales son parte de nuestra operación para producir alimentos para humanos. Por favor,
          consulte los respectivos sección para obtener más información.
        </p>
        <p>
          También hay algunos gatos alrededor que pasan el rato en los edificios o en el jardín, como áreas donde los
          perros no pueden ir.
        </p>

        <h2>Animales salvajes</h2>
        <p>
          Como estamos en una parte de la Dehesa española en un área con poca actividad humana, la vida silvestre nos
          visita con frecuencia. Hay un mayor número de aves que parece ir en aumento debido a nuestra actividad. A los
          pájaros les encanta pasar por el estiercol de las vacas, ya que encuentran larvas de moscas y otros insectos
          allí.
        </p>
        <p>
          Muy cerca hay un área protegida donde el <a target="_blank"
            href="https://es.wikipedia.org/wiki/Lynx_pardinus">lince ibérico</a>
          ha sido liberado. Todavía no hemos visto uno aquí. Otras especies de animales que hemos visto de cerca
          hasta ahora incluyen:
        </p>
        <ul>
          <li>Jabalí (Sus scrofa)</li>
          <li>Ciervo (Cervidae)</li>
          <li>Zorro</li>
          <li>Conejo (Oryctolagus cuniculus)</li>
        </ul>
        <p>
          Por supuesto, también hay otras criaturas más pequeñas como ratones y ratas. Hay ranas, lagartos y
          probablemente más de los que podemos recordar al momento de escribir.
        </p>
        <p>
          Algunas de estas especies animales son depredadores y atacan a nuestro ganado si no se protegen adecuadamente.
          Aceptamos ese hecho ya que es parte de cómo funciona la naturaleza y tratamos de proteger a nuestros animales
          de granja lo mejor posible.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/views/concept/Sidebar.vue';
import { setupFeatureFlags } from '@/library'

export default {
  name: "AnimalsES",
  components: {
    Sidebar,
  },

  setup () {
    return setupFeatureFlags()
  },

};
</script>
